import {
    Button,
    Card,
    DatePicker,
    Input,
    Divider,
    AutoComplete,
    Select,
    Form,
    Table,
    Skeleton,
    Empty,
    Tag,
    Modal,
    Checkbox,
    Popconfirm,
    Switch, Typography, notification,
    Spin,
    Image
} from "antd";

import {
    UserOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    PictureOutlined,
} from '@ant-design/icons';
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBaseUrl} from "./ApiConstants"
import { AppContext } from "../contexts/AppContext";


const DeletedCargo = ({ cargo, setShowDeletedCargo, setReload}) => {
    const [form] = Form.useForm();
    const { showNotification } = useContext(AppContext)

    const updateDeletedCargo = async (key) => {
        try {
            const config = {
                method: 'patch',
                url: `${getBaseUrl()}/cargo/${key}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                data: { isDeleted: false } 
            };
    
            await axios(config);
            
                showNotification('Lasten finns nu åter!', '', 'success', 3);
             // 3000 ms = 3 seconds
            setReload((prev) => !prev);
            setShowDeletedCargo(false);
        } catch (error) {
            console.error('Failed to update isDeleted:', error);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'isDeleted',
            width: '2%',
            render: (_, record) => (
                <span>
                    <a onClick={() => updateDeletedCargo(record.id)} style={{ color: 'green' }}>
                        +
                    </a>
                </span>
            ),
        },
        {
            title: 'Användare',
            dataIndex: 'username',
            width: '10%',
            
            editable: true,
            sorter: (a, b) => a.username.localeCompare(b.username),
           
        },
        {
            title: 'Material',
            dataIndex: 'material',
            width: '12%',
            sorter: (a, b) => a.material.localeCompare(b.material),
        },
        {
            title: 'Tidpunkt',
            dataIndex: 'time',
            width: '4%',
            sorter: (a, b) => a.time.localeCompare(b.time),
        },
        {
            title: 'Datum',
            dataIndex: 'date',
            width: '6%',
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            title: 'Vikt',
            dataIndex: 'weight',
            width: '6%',
            sorter: (a, b) => a.weight - b.weight,
            render: (text) => `${text} ton`,
        },
        {
            title: 'Littra',
            dataIndex: 'littera',
            width: '6%',
            sorter: (a, b) => a.littera.localeCompare(b.littera),
        },
        {
            title: 'Fordon',
            dataIndex: 'vehicle',
            width: '8%',
            sorter: (a, b) => a.vehicle.localeCompare(b.vehicle),
        },
        {
            title: 'Adress',
            dataIndex: 'origin',
            width: '8%',
            sorter: (a, b) => a.origin.localeCompare(b.origin),
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: '5%',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Tipplats',
            dataIndex: 'dumpSite',
            width: '10%',
            sorter: (a, b) => a.dumpSite.localeCompare(b.dumpSite),
        },
        {
            title: 'Provfack',
            dataIndex: 'sampleArea',
            width: '15%',
            sorter: (a, b) => a.sampleArea.localeCompare(b.sampleArea),
        },
    ];

    return (
        <div>
            <Form form={form} component={false}>
            <div
    style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        margin: 10,
        justifyContent: 'flex-end', 
        alignItems: 'center',
    }}
>
    <Button onClick={() => setShowDeletedCargo(false)} type="green">
        Tillbaka
    </Button>
</div>
                <Table
                    dataSource={cargo}
                    columns={columns}
                    rowKey="id" 
                    pagination={{ pageSize: 10 }}
                />
            </Form>
        </div>
    );
};

export default DeletedCargo;
